import axios from '@/libs/api.request.js'

export const get_users = (email) => {
  return axios.request({
    url: '/manage_app/get_users/',
    data: {'email':email},
    method: 'post'
  })
}

export const add_user_permission = (email_choice, permission_choice) => {
  return axios.request({
    url: '/manage_app/add_user_permission/',
    data: {'email_choice':email_choice,'permission_choice':permission_choice},
    method: 'post'
  })
}

export const reduce_user_permission = (email_choice, permission_choice) => {
  return axios.request({
    url: '/manage_app/reduce_user_permission/',
    data: {'email_choice':email_choice,'permission_choice':permission_choice},
    method: 'post'
  })
}

export const add_user_role = (email_choice, role_choice) => {
  return axios.request({
    url: '/manage_app/add_user_role/',
    data: {'email_choice':email_choice,'role_choice':role_choice},
    method: 'post'
  })
}

export const reduce_user_role = (email_choice, role_choice) => {
  return axios.request({
    url: '/manage_app/reduce_user_role/',
    data: {'email_choice':email_choice,'role_choice':role_choice},
    method: 'post'
  })
}

export const get_roles = (email) => {
  return axios.request({
    url: '/manage_app/get_roles/',
    data: {'email':email},
    method: 'post'
  })
}

export const add_role_permission = (role_choice, permission_choice) => {
  return axios.request({
    url: '/manage_app/add_role_permission/',
    data: {'role_choice':role_choice,'permission_choice':permission_choice},
    method: 'post'
  })
}

export const reduce_role_permission = (role_choice, permission_choice) => {
  return axios.request({
    url: '/manage_app/reduce_role_permission/',
    data: {'role_choice':role_choice,'permission_choice':permission_choice},
    method: 'post'
  })
}

export const flush_user_permission = (email) => {
  return axios.request({
    url: '/manage_app/flush_user_permission/',
    data: {'email':email},
    method: 'post'
  })
}