// 把token,email用cookie保存起来，不至于连接断开（比如刷新）用户登录信息就丢失

// js-cookie 需要 cnpm install js-cookie 下载  
import Cookies from 'js-cookie'
// cookie保存的天数
const cookieExpires = process.env.VUE_APP_cookieExpires
export const TOKEN_KEY = 'token' 
export const EMAIL_KEY = 'email'
export const PERMISSION_KEY = 'permission'

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires || 1})
}
export const setEmail = (email) => {
  Cookies.set(EMAIL_KEY, email, { expires: cookieExpires || 1})
}
export const setPermission = (permission) => {
  Cookies.set(PERMISSION_KEY, permission, { expires: cookieExpires || 1})
}

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY)
  if (token) return token
  else return false
}
export const getEmail = () => {
  const email = Cookies.get(EMAIL_KEY)
  if (email) return email
  else return false
}
export const getPermission = () => {
  const permission = Cookies.get(PERMISSION_KEY)
  if (permission) return permission
  else return false
}

// 时间戳转化成时间
export const timestampToTime = (timestamp) => {
    let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-'
    let M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-'
    let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
    let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
}


// 存储localStorage
export const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}


// 获取localStorage
export const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
}


// 删除localStorage
export const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
}