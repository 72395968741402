import Vue from 'vue'
import Vuex from 'vuex'
//模块化思维 =》与用户相关的存储写到这里
import user from './module/user'
import lazada from './module/lazada'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
  },
  mutations: {
    
  },
  actions: {
    //
  },
  modules: {
    user,
    lazada
  }
})