import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: '/index',
    },
    {
      path: "/index/",
      name: "index",
      meta:{
        //loginRequired:true,    // 自定义名称，添加该字段并设置路由守卫，表示进入这个路由是需要登录的
      },
      component: () => import("components/Manage-Index.vue")
    },
    {
      path: "/login/",
      name: "login",
      component: () => import("components/Manage-Login.vue")
    },
    {
      path: "/super_perm/",
      name: "super_perm",
      meta:{
        //loginRequired:true, 
        //permissionConfirmation:true    // 自定义名称，添加该字段并设置路由守卫，表示进入这个路由是需要确认权限的
      },
      component: () => import("components/Super-Perm.vue")
    },
    {
      path: "/goods_manage/",
      name: "goods_manage",
      component: () => import("components/Goods-Manage.vue")
    },
    {
      path: "/lazada_update_sku/",
      name: "lazada_update_sku",
      props: true,
      component: () => import("components/Lazada-Update-Sku.vue")
      //component: () => import("components/Lazada-U.vue")
    },
    {
      path: "/token_manage/",
      name: "token_manage",
      component: () => import("components/Token-Manage.vue")
    },
    {
      path: "/account_info/",
      name: "account_info",
      component: () => import("components/Account-Info.vue")
    },
    {
      path: "/sku_manage/",
      name: "sku_manage",
      component: () => import("components/Sku-Manage.vue")
    },
    {
      path: "/product_stock_manage/",
      name: "product_stock_manage",
      component: () => import("components/Product-Stock-Manage.vue")
    },
  ]
});