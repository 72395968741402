import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import {timestampToTime} from './libs/util.js' 
import draggable from 'vuedraggable' // 导入 vuedraggable   


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.use(router);
// 生产环境关掉提示
Vue.config.productionTip = false;
// 注册为全局组件
Vue.component('draggable', draggable); 

// 在Vue2中想要通过管道符使用过滤器，需要将函数注册为全局过滤器
Vue.filter('timestampToTime', function(value) {
  return timestampToTime(value);
});

router.beforeEach((to, from, next) => {
  // 判断当前path是否需要loginRequired才能访问
  if (to.matched.some(record => record.meta.loginRequired)) {
    if (store.state.user.email == '') {      
      alert('此界面需要登录才能访问!')
      next({
        path: '/login/',
      })
    } else {
      next()
    }
  }
  next() // 确保一定要调用 next()
})

router.beforeEach((to, from, next) => {
  // 判断当前path是否需要permissionConfirmation才能访问
  if (to.matched.some(record => record.meta.permissionConfirmation)) {
    if (store.state.user.permission && store.state.user.permission.split(',').includes(to.name)) {
      next()
    } else {
      alert('此界面需要权限才能访问!')
      next({
        path: '/index/',
      })
    }
  }
  next()
});


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
