export default {
  state: {
    goods_info: [],
  },
  mutations: {
    setGoodsInfo (state, goods_info) {
      state.goods_info = goods_info
    }
  },
  getters: {
  },
  actions: {
  }


}