<template>
<div>
         
  <el-container>
      <el-header style="padding: 0;">
          <el-menu
          :default-active="activeIndex2"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#BCC6C3"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item index="1"><router-link :to="{ path: '/index/'}" replace>首页</router-link></el-menu-item>
          <el-submenu index="2" :style="{ display: 'none' }">
            <template slot="title">路由测试</template>
            <el-menu-item v-for="(permission, index) in permission_list" 
                  :key="index" 
                  :index="permission">
                <router-link :to="{ path: permission }" replace>{{ permission }}</router-link>
            </el-menu-item>
          </el-submenu>
          
          <el-submenu index="3">
            <template slot="title">信息统计</template>  
                <el-menu-item index="3-1"><router-link :to="{ path: '/account_info/' }" replace>店铺发货指标统计</router-link></el-menu-item>
                <el-menu-item index="3-2"><router-link :to="{ path: '/product_stock_manage/' }" replace>精致商品库存查询</router-link></el-menu-item>
          </el-submenu>
          
          <el-submenu index="4">
            <template slot="title">管理</template>  
                <el-menu-item index="4-1" v-if="vue_update_button"><router-link :to="{ path: '/goods_manage/' }" replace>商品管理</router-link></el-menu-item>
                <el-menu-item index="4-2" v-if="vue_update_button"><router-link :to="{ path: '/token_manage/' }" replace>Token管理</router-link></el-menu-item>
                <el-menu-item index="4-3" v-if="vue_update_button"><router-link :to="{ path: '/sku_manage/' }" replace>SKU管理</router-link></el-menu-item>
          </el-submenu>
          <el-menu-item index="5" style="float: right;">
              <template>
               <el-image style="width: 100px; height: 40px" :src="this.url" fit="contain"></el-image>
                {{ email }}
              </template>
          </el-menu-item>
          <el-submenu index="6" style="float: right;">
            <template slot="title">账号</template>
                <el-menu-item index="6-1"><el-button type="danger" size="mini" @click="Logout">退出</el-button></el-menu-item>
                <el-menu-item index="6-2">
                    <el-button type="success" size="mini">
                        <router-link :to="{ path: '/login/' }" replace>登录</router-link>
                    </el-button>
                </el-menu-item>
          </el-submenu>
          </el-menu>
      </el-header>
  <el-container>
      
    <el-main style="padding: 0;width: 100%;"><router-view></router-view></el-main>
  </el-container>
  </el-container>  
  
</div>
</template>


<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import {flush_user_permission} from './api/manage.js'
export default {
    name: 'App',
    components: {

    },
    data () {
        return {
          activeIndex2: '1',
          email: '',
          url: '',
          permission_list: [],
          vue_update_button: process.env.VUE_APP_update_button == 1,
        }
    },
    computed: {
      ...mapState({
         user:state=>state.user
      })
    },
    methods: {
        //  成功通知
        success_notice(notice) {
            this.$notify({
                title: '成功',
                message: notice,
                type: 'success'
            })
        },
        //  错误通知
        err_notice(notice) {
            this.$notify.error({
                title: '通知',
                message: notice,
            });
        },
        //错误弹窗
        open(msg) {
            this.$alert(msg, '未能通过', {
              confirmButtonText: '确定',
              callback:() => {
                this.password = ''
              }
            });
        },        
        // 
        ...mapActions(['handleLogOut']),
        ...mapMutations(['setPermission']),
        Logout () {
              this.handleLogOut().then( () => {
                }).catch((err) => {
                    this.open(err)
              })
              location.reload();
        },
        //  刷新首页路由
        flush_user_permission() {
          flush_user_permission(this.user.email).then( (response) => {
            var data = response.data
            if (data.code == 0) {       
              this.success_notice(data.msg)
              this.setPermission(data.data.permissions)
              location.reload()
            }else{
              this.err_notice(data.msg)
            }
          }).catch((err) => {
            console.log(err)
          });
        },
        
    },
    beforeMount() {
        // 显示用户邮箱
        if(this.user.email==false) {
            this.email = '未登录'
        }else{
           this.email = this.user.email 
        }
        // 根据用户显示菜单
        if((this.user.permission == false)) {
            void 0
        }else{
            this.permission_list = this.user.permission.split(',');
        }
        this.url = require('assets/images/Avatar.jpg')
    }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  height: 665px;
}

a:-webkit-any-link {
    color: white;
    text-decoration: none;
}

</style>
