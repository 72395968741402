import { login } from '@/api/user'
import { setToken,getToken,setEmail,getEmail,setPermission,getPermission } from '@/libs/util'

export default {
  state: {  //需要保存的数据
    // email，token初始化是一个方法，这个方法从cookie中获取数据，cookie中没有数据则返回false，即未登录前email和token都为false。
    // 当登录时，触发actions，会直接用email，token真实数据覆盖方法，同时使用setEmail，setToken保存至cookie。
    // 后续页面刷新时，vuex里的email，token消失。重新变为getEamil，getToken方法，此时会自动从cookie里面get数据。
    email: getEmail(),
    token: getToken(),
    permission: getPermission(),
  },
  // mutation:突变，变化。 即设置、修改state数据
  mutations: {
    setEmail (state, email) {
      // 保存至state
      state.email= email
      // 保存至cookie
      setEmail(email)
    },
    setToken (state, token) {
      state.token = token
      setToken(token)
    },
    setPermission (state, permission) {
      state.permission = permission
      setPermission(permission)
    }
  },
  // 获取数据的一些方法
  getters: {
  },
  actions: {
    // 登录行为，退出行为
    handleLogin ({ commit }, [email, password]) {
      return new Promise( (resolve, reject) => {
        login(email, password).then( (response)=> {
          const data = response.data
          if(data.code == 0){
              console.log(data)
            // 保存email,token,permission
            commit('setToken', data.data.token)
            commit('setEmail', data.data.email)
            commit('setPermission', data.data.permissions)
            // 账号密码正确就resolve()，即此时被调用会触发.then()
            resolve()
          }else if (data.code == 1){
            // 账号密码不正确调用reject()，意味被调用时会触发.catch()
            reject(response.msg)
          }
        })
      })
    },
    handleLogOut ({ commit }) {
      return new Promise( (resolve) => {
          commit('setToken', '')
          commit('setEmail', '')
          commit('setPermission', '')
          resolve()
      })
    }
  }
}